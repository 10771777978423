import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LastUpdate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCircle } from "@fortawesome/free-solid-svg-icons";

const LastUpdate = () => {
  const [lastDate, setLastDate] = useState("Fetching last update...");
  const [weekDay, setWeekDay] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  useEffect(() => {
    const fetchLastUpdate = async () => {
      try {
        const response = await axios.get(`/api/last-update/`);
        if (response.data && response.data.last_updated) {
          formatDate(response.data.last_updated);
        } else {
          setLastDate("No data available");
          setIsActive(false);
        }
      } catch (error) {
        console.error("Failed to fetch last update:", error);
        setLastDate("Fetching last update failed");
        setIsActive(false);
      }
    };
    fetchLastUpdate();
    const intervalId = setInterval(fetchLastUpdate, 86400000); 
    return () => clearInterval(intervalId);
  }, []);

  


  const formatDate = (formatted_date) => {
    if (formatted_date) {
      const date = new Date(formatted_date);
      const options = {
        timeZone: "America/Vancouver",
        weekday: "long",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      setLastDate(formattedDate);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      setWeekDay(dayOfWeek);
    }
  };

  const squareStyle = (index) => {
    if (weekDay === "Saturday" || weekDay === "Sunday") {
      return { color: "#b9d2dd" };
    } else if (index === days.indexOf(weekDay)) {
      return { color: "#004596" };
    } else {
      return { color: "#b9d2dd" };
    }
  };

  return (
    <div className="last-update-container">
      <div className="last-update-message-container">
        <h2>Last Update</h2>
        <div className="upload-data">
          <div className="day-of-week-icons">
            {days.map((day, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faSquare}
                style={{
                  ...squareStyle(index),
                  margin: "5px",
                }}
              />
            ))}
          </div>
          {lastDate ? (
            <>
              {lastDate}{" "}
              <FontAwesomeIcon
                icon={faCircle}
                className="circle-icon"
                style={{ color: isActive ? "green" : "grey" }}
              />
            </>
          ) : (
            "Connection to QuickBooks is closed - no current data."
          )}
        </div>
      </div>
      <div id="demo-message">
        <h3>
          Demo Notes
        </h3>
        <p>
        Please note that this application serves as a demonstration model.
        It is not currently integrated with QuickBooks, and the Server-Sent Events (SSE) streaming functionality has been temporarily deactivated for this demo version.
        </p>
      </div>
    </div>
  );
};

export default LastUpdate;
